/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2020-09-04 09:37:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
    sortList: [
      {
        name: "发布时间",
        sortStatus: 1 //0默认 1降 2升序
      }
    ],
  };
  