import { mapState } from "vuex";
import { quetionList, closeQuery } from "./api";
import util from "./components/until";
// import sortBar from "./components/SortBar";
import sortBar from "./components/SortBar";
import { infoImgUrl } from "@/common/constant.js";
export default {
  components: {
    sortBar
  },
  name: "index",
  data() {
    return {
      desc: ['非常差', '差', '一般', '好', '非常好'],
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/noticeList",
          name: "noticeList",
          title: "咨询投诉"
        }
      ],
      isActiveButton: '-1',
      evaluateGroup: [
        {
          id: 0,
          name: '结果满意'
        },
        {
          id: 1,
          name: '回复速度快'
        },
        {
          id: 2,
          name: '态度好'
        }
      ],
      // windowList: [],
      sortList: [],
      value: 2,
      startNo: 0,
      qustionArrList: [],
      filterFrom: {
        sortName: '',
        page: 1,
        pageSize: 20,
        questionLevel: '',
        answerFlag: '',
        'questionType.propertyValue': '',
        title: "",
        id: '',
      },
      isSearch: true,
      total: '',//搜索到几条数据
      totalPages: '',//数据总条数
      currentPage: '',//当前页数

    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    if (this.$route.query.id) {
      this.filterFrom.id = this.$route.query.id
      // this.filterFrom = { ...this.filterFrom, title };
    }
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode: this.filterConfigStr ? this.filterConfigStr : '' };
    this.sortList = util.sortList;
    // this.getconsultData()
    // this.searchList()


  },
  methods: {
    downFile(item) {
      let fileStr = item.attachment.replace(/[//]/g,'')
      let fileArr = fileStr.split(',')
      fileArr.forEach(item=>{
        window.open(infoImgUrl + '/question/' + item, "_blank");
      })
    },
    changeStart(value, messageIndex) {
      this.$set(this.qustionArrList, messageIndex, { ...this.qustionArrList[messageIndex], startnum: value })
    },
    closeStart(messageIndex) {
      if (this.qustionArrList[messageIndex].startnum == 0) {
        this.$message.warning('请先进行评分')
        return false
      }
      let data = {
        id: this.qustionArrList[messageIndex].id,
        score: this.qustionArrList[messageIndex].startnum,
        feel: this.qustionArrList[messageIndex].evaluate,
      }
      closeQuery(data).then(res => {

        if (res.data == "success") {
          this.$message.success('成功关闭问题')
          this.searchList()
        } else {
          this.$message.warning('关闭问题失败')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    btnClick(id, evaluateIndex, messageIndex, name) {
      this.$set(this.qustionArrList, messageIndex, { ...this.qustionArrList[messageIndex], isActiveButton: evaluateIndex })
      this.$set(this.qustionArrList, messageIndex, { ...this.qustionArrList[messageIndex], problemType: name })
    },
    // 查询列表 
    async searchList() {
      try {
        this.pageLoadFlag = true
        const res = await quetionList(this.filterFrom);
        this.qustionArrList = res.data.messageList

        if (this.qustionArrList.length > 0) {
          this.qustionArrList.forEach(item => {
            item.isActiveButton = -1
            item.problemType = null,
              item.startnum = 0
            item.evaluate = null
          })
        }
        // 查询出来的总数量
        this.total = res.data.totalCount
        // // 重新赋值页数
        this.totalPages = res.data.pageSize
        this.currentPage = res.data.currentPage
        this.pageLoadFlag = false;
      } catch (error) {
        console.log(error);
        this.pageLoadFlag = false;
      }
    },
    loadMore() {
      this.filterFrom = {
        ...this.filterFrom,
        page: this.filterFrom.page + 1
      };
      this.pageLoadFlag = true;
      quetionList(this.filterFrom).then(res => {
        // 点击加载更多 拼接数组
        this.qustionArrList = this.qustionArrList.concat(res.data.messageList)
        this.pageLoadFlag = false;
        this.totalPages = res.data.pageSize
        this.currentPage = res.data.currentPage
      }).catch(error => {
        console.log(error)
      })
    },
    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0], page: 1 };
      //查询列表
      this.qustionArrList = [];
      this.searchList();
      this.isSearch = false
    },
    // 更改搜索框内容
    changeSearchVal(searchStr) {
      this.filterFrom = { ...this.filterFrom, title:searchStr };
    },
    onSearch(title) {
      this.qustionArrList = [];
      this.filterFrom = { ...this.filterFrom, title };
      this.searchList();
      this.isSearch = false
    },
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.filterFrom = {
        ...this.filterFrom,
        sortName: 'startTime',
        sortOrder: sortStatus == 0 ? "" : sortStatus == 1 ? "desc" : "asc"
      };
      this.searchList()
    },
  }
}


