import http from "@/utils/request";
export function messageFilterList(data) {
    return http({
      method: "get",
      url: "/priceMessage/messageList.nd",
      data
    });
  }
// 咨询问题查询条件  
export function consultFilterList(data) {
    return http({
      method: "get",
      url: "/question/commitQuestion.htm",
      data
    });
  }


  export function quetionList(data) {
      return http({
          method: "post",
          url: "/question/mineListNew.nd",
          params: data
      });
  }


export function closeQuery(data) {
  return http({
      method: "post",
      url: "/question/closeQuestion.nd",
      data
  });
}